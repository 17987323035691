












import Vue from 'vue';
import Component from 'vue-class-component';
import { mixin as clickaway } from 'vue-clickaway';

@Component({
  mixins: [
    clickaway,
  ],
})
export default class CommonSupportFlyout extends Vue {
  isOpen = false;

  closeFlyout (): void {
    this.$nextTick(() => {
      if (this.isOpen) {
        this.isOpen = false;
      }
    });
  }

  get rootClass (): Record<string, boolean> {
    return {
      'common-support-flyout--open': this.isOpen,
    };
  }
}
